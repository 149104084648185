import { Grid, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { withRouter } from 'react-router-dom'
import { withPaths, withEpisodes } from '../../contexts/data-context'
import Helper from '../../utils/Helper'
import { DeleteChapter } from '../Delete'
import { CancelButton, DeleteButton, SaveButton } from '../Forms'
import CardControl from '../ImageControl'
import { DangerZone } from '../Grids'
import EpisodeField from './EpisodeField'
import UploadField from './UploadField'
import IsFreeField from './IsFreeField'
import StateField from './StateField'
import UrlField from './UrlField'
import SlugField from './SlugField'
import TitleField from './TitleField'
import { assetServer } from '../../utils/url'
import PdfUploadField from '../PdfUploadField'

const playerConfig = {
  file: {
    hlsOptions: {
      forceHLS: true,
      debug: false,
      xhrSetup: function (xhr, url) {
        if (url.endsWith('streaming')) {
          xhr.setRequestHeader('Authorization', Helper.token())
        }
      },
    },
  },
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
})

const ChapterForm = (props: any) => {
  const { history, classes, episodes, chapter, onChange, onSave } = props
  const {
    _id,
    title = '',
    slug = '',
    state = 'draft',
    thumbnail = '',
    isFree = false,
    url = '',
    episode = '',
    handbook = '',
  } = chapter

  const [isLoading, setIsLoading] = useState(false)
  const [disable, setDisable] = useState(false)

  const [handbookFile, setHandbookFile] = useState(null)

  const handleCancel = () => {
    history.goBack()
  }

  const handleSave = async (e) => {
    // We don't want submit to refresh the page
    if (e) {
      e.preventDefault()
    }

    try {
      if (handbookFile) chapter.handbook = await handleUploadPdf(handbookFile)
    } catch (error) {
      console.log(error)
    }
    return onSave()
  }

  const handleAfterDelete = () => {
    history.push(`/episodes/${episode.slug}/chapters`)
  }

  const handleChangeEpisode = (event) => {
    chapter.episode = episodes.find((e) => e._id === event.target.value)
    onChange(chapter)
  }

  const handleChange = (field) => (event) => {
    chapter[field] = event.target.value
    onChange(chapter)
  }

  const handleChangeUrl = (field) => (value) => {
    chapter[field] = value
    onChange(chapter)
  }

  const handleChangeChecked = (field) => (event) => {
    chapter[field] = event.target.checked
    onChange(chapter)
  }

  const handleFileUpload = async (event) => {
    setIsLoading(true)
    setDisable(true)
    try {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('upload', file)
      const detail = await Helper.uploadVideoToHLS(formData)
      if (detail && detail.s3url) {
        chapter.url = detail.s3url
        setIsLoading(false)
        setDisable(false)
        onChange(chapter)
      }
    } catch (e) {
      setIsLoading(false)
      setDisable(false)
    }
  }

  const handlePdfFileUpload = (event) => {
    const uploadedFile = event.target.files[0]

    setHandbookFile(uploadedFile)
  }

  const cancelUploadPdf = () => {
    if (handbookFile) {
      setHandbookFile(null)
    }

    if (handbook) {
      delete chapter.handbook
      onChange({ ...chapter, handbook: '' })
    }
  }

  const handleUploadPdf = async (file) => {
    const uploadName = 'pdf/' + file.name
    const formData = new FormData()
    formData.append('upload', file)
    await Helper.uploadAsset(uploadName, formData)
    return assetServer + uploadName
  }

  const textProps = {
    classes,
    handleChange,
  }
  const checkProps = {
    classes,
    handleChange: handleChangeChecked,
    isFree,
  }
  const episodeProps = {
    classes,
    onChange: handleChangeEpisode,
    episodes,
    id: episode._id || '',
    disabled: !!_id,
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={24} direction="row" alignItems="flex-start">
        <Grid item xs={12}>
          <EpisodeField {...episodeProps} />
        </Grid>
        <Grid container direction="column" item xs={12} sm={6}>
          <Grid item xs={12}>
            <TitleField {...textProps} value={title} />
          </Grid>
          <Grid>
            <SlugField {...textProps} value={slug} />
          </Grid>
          <Grid container item direction="row">
            <Grid item sm={6}>
              <StateField {...textProps} value={state} />
            </Grid>
            <Grid item xs={6}>
              <IsFreeField
                {...checkProps}
                value={isFree}
                disabled={episode.isAnon}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" item xs={12} sm={6}>
          <Grid item xs={12}>
            <UrlField {...textProps} value={url} disable={disable} />
          </Grid>
          <Grid item xs={12}>
            <ReactPlayer
              url={url}
              width="100%"
              height="100%"
              controls
              config={playerConfig}
            />
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <UploadField classes={classes} onChange={handleFileUpload} />
            )}
          </Grid>
        </Grid>

        <Grid container direction="row">
          <Grid item xs={12} sm={6}>
            <CardControl
              // @ts-expect-error legacy
              url={thumbnail}
              onChangeUrl={handleChangeUrl('thumbnail')}
              title="Thumbnail"
              uploadDefaultPrefix="thumbs"
              uploadDefaultName={`th-${slug}`}
              onUpload={handleSave}
            />
          </Grid>

          {!episode.isAnon && (
            <Grid item xs={12} sm={6}>
              <PdfUploadField
                classes={classes}
                onUpload={handlePdfFileUpload}
                title={'Handbook'}
                value={handbookFile ? handbookFile.name : handbook}
                url={handbookFile ?? handbook}
                onCancel={cancelUploadPdf}
              />
            </Grid>
          )}
        </Grid>

        <Grid item container xs={12} justify="flex-end">
          <Grid item xs={12} sm={6}>
            <SaveButton onClick={handleSave} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CancelButton onClick={handleCancel} />
          </Grid>
        </Grid>
        {chapter && chapter._id && (
          <DangerZone>
            <DeleteChapter
              chapter={chapter}
              onAfterDelete={handleAfterDelete}
              component={DeleteButton}
            />
          </DangerZone>
        )}
      </Grid>
    </form>
  )
}

ChapterForm.defaultProps = {
  chapter: {},
}
ChapterForm.propTypes = {
  classes: PropTypes.object.isRequired,
  chapter: PropTypes.object,
}

export default withEpisodes(
  withPaths(withRouter(withStyles(styles)(ChapterForm))),
)
