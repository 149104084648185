import React, { Component } from 'react'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import {
  SignIn,
  Episodes,
  Levels,
  EpisodeDetail,
  EpisodeAdd,
  ChapterDetail,
  ChapterAdd,
  GameAdd,
  GameDetail,
  MapDetail,
  Onboardings,
  OnboardingAdd,
  Features,
  Categories,
  CategoryAdd,
  CategoryDetail,
} from './pages'
import Helper from './utils/Helper'
import Layout from './layout'
import Maps from './pages/maps/Maps'
import OnboardingDetail from './pages/onboardings/OnboardingDetail'
import Settings from './pages/settings/Settings'
import BookAdd from './pages/books/BookAdd'
import BookDetail from './pages/books/BookDetail'
import LocalNotification from './pages/localNotification/LocalNotification'
import Series from './pages/series/Series'
import SeriesDetail from './pages/series/SeriesDetail'
import SeriesAdd from './pages/series/SeriesAdd'
import Period from './pages/periods/Period'
import CompetitionDetails from './pages/competitions/CompetitionDetails'
import Competition from './pages/competitions/Competition'
import CompetitionAdd from './pages/competitions/CompetitionAdd'
import ChallengesAdd from './pages/competitions/challenges/ChallengesAdd'
import ChallengeDetails from './pages/competitions/challenges/ChallengeDetails'

const Routes = () => (
  <Switch>
    <Route exact path="/login" component={SignIn} />
    <Route exact path="/" render={() => <Redirect to="/episodes" />} />
    <PrivateRoute
      exact
      layout={Layout}
      path="/onboardings"
      component={Onboardings}
    />
    <PrivateRoute
      exact
      layout={Layout}
      path="/onboardings/add"
      component={OnboardingAdd}
    />
    <PrivateRoute
      exact
      layout={Layout}
      path="/onboardings/edit/:slug"
      component={OnboardingDetail}
    />
    <PrivateRoute exact layout={Layout} path="/series" component={Series} />
    <PrivateRoute
      exact
      layout={Layout}
      path="/series/add"
      component={SeriesAdd}
    />
    <PrivateRoute
      exact
      layout={Layout}
      path="/series/:slug"
      component={SeriesDetail}
    />
    <PrivateRoute exact layout={Layout} path="/episodes" component={Episodes} />
    <PrivateRoute
      exact
      layout={Layout}
      path="/episodes/add"
      component={EpisodeAdd}
    />
    <PrivateRoute
      exact
      layout={Layout}
      path="/episodes/:slug/:tab?"
      component={EpisodeDetail}
    />
    <PrivateRoute
      exact
      layout={Layout}
      path="/chapters/add"
      component={ChapterAdd}
    />
    <PrivateRoute
      exact
      layout={Layout}
      path="/chapters/:slug"
      component={ChapterDetail}
    />

    <PrivateRoute exact layout={Layout} path="/games/add" component={GameAdd} />
    <PrivateRoute
      exact
      layout={Layout}
      path="/games/:id"
      component={GameDetail}
    />

    <PrivateRoute exact layout={Layout} path="/books/add" component={BookAdd} />
    <PrivateRoute
      exact
      layout={Layout}
      path="/books/:id"
      component={BookDetail}
    />

    <PrivateRoute exact layout={Layout} path="/maps" component={Maps} />
    <PrivateRoute
      exact
      layout={Layout}
      path="/maps/:id"
      component={MapDetail}
    />
    <PrivateRoute exact layout={Layout} path="/levels" component={Levels} />
    <PrivateRoute exact layout={Layout} path="/features" component={Features} />

    <PrivateRoute exact layout={Layout} path="/settings" component={Settings} />
    <PrivateRoute
      exact
      layout={Layout}
      path="/local-notification"
      component={LocalNotification}
    />

    <PrivateRoute
      exact
      layout={Layout}
      path="/categories"
      component={Categories}
    />
    <PrivateRoute
      exact
      layout={Layout}
      path="/categories/add"
      component={CategoryAdd}
    />
    <PrivateRoute
      exact
      layout={Layout}
      path="/categories/edit/:slug"
      component={CategoryDetail}
    />
    <PrivateRoute
      exact
      layout={Layout}
      path="/taalam-periods"
      component={Period}
    />

    <PrivateRoute
      exact
      layout={Layout}
      path="/competitions"
      component={Competition}
    />

    <PrivateRoute
      exact
      layout={Layout}
      path="/competitions/add"
      component={CompetitionAdd}
    />

    <PrivateRoute
      exact
      layout={Layout}
      path="/competitions/:id"
      component={CompetitionDetails}
    />

    <PrivateRoute
      exact
      layout={Layout}
      path="/challenges/add/:id"
      component={ChallengesAdd}
    />

    <PrivateRoute
      exact
      layout={Layout}
      path="/challenges/:id"
      component={ChallengeDetails}
    />

    {/* <Route component={NotFound} /> */}
  </Switch>
)

const PrivateRoute = ({ layout: Layout, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!Helper.isAuthenticated()) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }

      if (Layout) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
      return <Component {...props} />
    }}
  />
)

class Routing extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    )
  }
}

export default Routing
