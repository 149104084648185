import React from 'react'
import {
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Slide,
  DialogActions,
} from '@material-ui/core'

function Transition(props: any) {
  return <Slide direction="up" {...props} />
}
const ConfirmationDialog = ({
  open,
  title = '',
  text,
  onCancel,
  onOk,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.defaultProps = {
  title: '',
  text: '',
  open: false,
}

export { ConfirmationDialog }
