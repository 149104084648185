import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import SeriesForm from '../../components/SeriesForm'
import Helper from '../../utils/Helper'
import { doAndSnack, snackProgressBar } from '../../utils/snackbar'

const styles: any = (theme) => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
  },
})

const SeriesAdd = (props: any) => {
  const { classes, enqueueSnackbar, history } = props
  const [series, setSeries] = useState({
    title: '',
    slug: '',
    order: 1,
    state: '',
    path: '',
    // isFree: '',
    image: '',
    thumbnail: '',
    description: '',
  })

  const saveSeries = () => {
    try {
      if (!series.title) {
        snackProgressBar(
          enqueueSnackbar,
          {
            success: '',
            fail: 'Title is required',
          },
          false,
        )
        return
      }

      if (!series.slug) {
        snackProgressBar(
          enqueueSnackbar,
          {
            success: '',
            fail: 'Slug is required',
          },
          false,
        )
        return
      }

      const promise = Helper.ApiRequest('/series', {
        method: 'POST',
        body: JSON.stringify(series),
      })

      const onSuccess = () => {
        history.push('/series')
      }

      doAndSnack(promise, enqueueSnackbar, {
        success: 'Series succesfully saved',
        fail: 'Failed to save series',
        onSuccess,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (series) => {
    setSeries({ ...series })
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        Add a new series
      </Typography>
      <Paper className={classes.paper}>
        <SeriesForm
          // @ts-expect-error legacy
          series={series}
          onChange={handleChange}
          onSave={saveSeries}
        />
      </Paper>
    </React.Fragment>
  )
}

export default withSnackbar(withRouter(withStyles(styles)(SeriesAdd)))
