import React, { useEffect, useState } from 'react'
import { withStyles, withTheme } from '@material-ui/core/styles'
import { withDrawerContext } from '../../../contexts/drawer-context'
import { withSnackbar } from 'notistack'
import { withRouter, useParams, Link } from 'react-router-dom'
import { CircularProgress, Paper, Typography } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import ChallengeForm from '../../../components/ChallengeForm'
import { IChallenge, ICompetitionNew } from '../type'
import Helper from '../../../utils/Helper'
import { doAndSnack, snackProgressBar } from '../../../utils/snackbar'
import { OUT_OF_BOUND_DATE_MESSAGE } from '../../../constants/ErrorMessages'

const styles: any = (theme) => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
  fab: {
    top: 'auto',
    right: '3rem',
    bottom: '2rem',
    left: 'auto',
    position: 'fixed',
  },
})

function basicValidateCompetition(challenge: IChallenge, enqueueSnackbar) {
  const validations = [
    { check: () => !challenge?.title, message: 'Title is required' },
    {
      check: () => !challenge?.url,
      message: 'Url is required',
    },
    { check: () => !challenge?.startDate, message: 'Start Date is required' },
    { check: () => !challenge?.endDate, message: 'End Date is required' },
  ]

  for (const validation of validations) {
    if (validation.check()) {
      snackProgressBar(
        enqueueSnackbar,
        { success: '', fail: validation.message },
        false,
      )
      return false
    }
  }

  return true
}

const ChallengeDetails = (props) => {
  const { classes, enqueueSnackbar, history, location } = props

  const { id } = useParams()

  const [challenge, setChallenge] = useState<IChallenge>(location.state)

  const [competition, setCompetition] = useState<ICompetitionNew>()
  const [isLoadingCompetition, setIsLoadingCompetition] = useState(true)

  useEffect(() => {
    const fetchCompetition = async () => {
      try {
        setIsLoadingCompetition(true)
        const result = await Helper.ApiRequest(`/competitionsNew/${id}`)

        setCompetition(result)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoadingCompetition(false)
      }
    }

    fetchCompetition()
  }, [id])

  const handleChange = (challenge) => {
    setChallenge({
      ...challenge,
    })
  }

  const saveChallenge = () => {
    const isChallengeValidate = basicValidateCompetition(
      challenge,
      enqueueSnackbar,
    )

    if (!isChallengeValidate) return

    const challStartDate = new Date(challenge?.startDate)
    const challEndDate = new Date(challenge?.endDate)
    const compStartDate = new Date(competition?.startDate)
    const compEndDate = new Date(competition?.endDate)

    if (challStartDate > compEndDate || challEndDate < compStartDate) {
      snackProgressBar(
        enqueueSnackbar,
        { success: '', fail: OUT_OF_BOUND_DATE_MESSAGE },
        false,
      )
      return
    }

    challEndDate.setUTCHours(23, 59, 59, 999)
    challenge.endDate = challEndDate.toISOString()

    const promise = Helper.ApiRequest(`/challenges/${challenge._id}`, {
      method: 'PUT',
      body: JSON.stringify(challenge),
    })

    const onSuccess = () => {
      history.push('/competitions')
    }

    doAndSnack(promise, enqueueSnackbar, {
      success: 'Challenge succesfully updated',
      fail: 'Failed to update challenge',
      onSuccess,
    })
  }

  // Filter out other challenges to get the reserved date
  const reservedDates = competition?.challenges
    .filter(
      (oneChallenge) =>
        challenge?._id !== oneChallenge._id ||
        oneChallenge.state !== 'published',
    )
    .map((challenge: IChallenge) => {
      const startDate = new Date(challenge.startDate.split('T')[0])
      const endDate = new Date(challenge.endDate.split('T')[0])

      // Set start date to midnight (00:00)
      startDate.setHours(0, 0, 0, 0)
      // Set end date to the end of the day (23:59:59:999)
      endDate.setHours(23, 59, 59, 999)
      return {
        startDate,
        endDate,
      }
    })

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        Edit challenge
        <Link to={`/competitions/${id}`}>
          <LinkIcon />
        </Link>
      </Typography>
      {!isLoadingCompetition ? (
        <Paper className={classes.paper}>
          <ChallengeForm
            // @ts-expect-error legacy
            challenge={challenge}
            onChange={handleChange}
            onSave={saveChallenge}
            reservedDates={reservedDates}
          />
        </Paper>
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </>
  )
}

export default withSnackbar(
  // @ts-expect-error legacy
  withDrawerContext(
    withRouter(withTheme()(withStyles(styles)(ChallengeDetails))),
  ),
)
