import {
  CircularProgress,
  Fab,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { withDrawerContext } from '../../contexts/drawer-context'
import { withStyles, withTheme } from '@material-ui/core/styles'
import { withRouter, useParams } from 'react-router-dom'
import { IChallenge, ICompetitionNew } from './type'
import CompetitionForm from '../../components/CompetitionForm'
import ChallengeTable from '../../components/ChallengeTable'
import AddIcon from '@material-ui/icons/Add'
import { doAndSnack, snackProgressBar } from '../../utils/snackbar'
import Helper from '../../utils/Helper'
import { OVERLAP_DATE_MESSAGE } from '../../constants/ErrorMessages'

function basicValidateCompetition(
  competition: ICompetitionNew,
  enqueueSnackbar,
) {
  const validations = [
    { check: () => !competition?.title, message: 'Title is required' },
    {
      check: () => competition?.selectedPaths?.length === 0,
      message: 'Level is required',
    },
    { check: () => !competition?.startDate, message: 'Start Date is required' },
    { check: () => !competition?.endDate, message: 'End Date is required' },
  ]

  for (const validation of validations) {
    if (validation.check()) {
      snackProgressBar(
        enqueueSnackbar,
        { success: '', fail: validation.message },
        false,
      )
      return false
    }
  }

  return true
}

const styles: any = (theme) => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
  fab: {
    top: 'auto',
    right: '3rem',
    bottom: '2rem',
    left: 'auto',
    position: 'fixed',
  },
})

const CompetitionDetails = (props) => {
  const { classes, history, location, enqueueSnackbar }: any = props

  const [competition, setCompetition] = useState<ICompetitionNew>()
  const [title, setTitle] = useState()
  const [loadingChallenges, setLoadingChallenges] = useState(true)
  const [loadingOneCompetition, setLoadingOneCompetition] = useState(true)
  const [loadingAllCompetition, setLoadingAllCompetition] = useState(true)
  const [challenges, setChallenges] = useState([])
  const [allCompetitions, setAllCompetitions] = useState<ICompetitionNew[]>()
  const [reservedDates, setReservedDates] = useState([])

  const [tabValue, setTabValue] = useState(0)

  const { id } = useParams()

  const isLoading =
    loadingChallenges || loadingOneCompetition || loadingAllCompetition

  useEffect(() => {
    const fetchCompetition = async () => {
      try {
        setLoadingOneCompetition(true)
        const result = await Helper.ApiRequest(`/competitionsNew/${id}`)

        setTitle(result?.title)

        setCompetition(result)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingOneCompetition(false)
      }
    }

    fetchCompetition()
  }, [id])

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        setLoadingChallenges(true)
        const result = await Helper.ApiRequest(`/challenges/all/${id}`)
        setChallenges(result)
      } catch (error) {
        console.log(error)
        snackProgressBar(
          enqueueSnackbar,
          {
            success: '',
            fail: 'Failed to fetch data. Please refresh the page',
          },
          false,
        )
      } finally {
        setLoadingChallenges(false)
      }
    }

    fetchChallenges()
  }, [id, enqueueSnackbar])

  useEffect(() => {
    // get all competitions to checked if date will be overlapped
    const fetchAllCompetitions = async () => {
      try {
        setLoadingAllCompetition(true)

        const result = await Helper.ApiRequest('/competitionsNew')

        setAllCompetitions(result)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingAllCompetition(false)
      }
    }

    fetchAllCompetitions()
  }, [])

  const addChallenge = (enqueueSnackbar) => {
    if (!competition?._id) {
      snackProgressBar(
        enqueueSnackbar,
        {
          success: '',
          fail: 'Data error with this competition, It might not have an ID',
        },
        false,
      )

      return
    }
    history.push(`/challenges/add/${competition._id}`)
  }

  const handleChangeTab = (e, value) => {
    setTabValue(value)
  }

  const handleChange = (competition) => {
    setCompetition({
      ...competition,
    })
  }

  useEffect(() => {
    const calculateReservedDates = () => {
      const selectedPathsSet = new Set(
        competition?.selectedPaths.map((path) => path._id),
      )

      const filteredCompetition = allCompetitions?.filter((oneCompetition) => {
        const isDifferentCompetition = competition?._id !== oneCompetition._id
        const isPublished = oneCompetition.state === 'published' // Competitions that are published
        const hasOverlappingPaths = oneCompetition.selectedPaths.some((path) =>
          selectedPathsSet.has(path._id),
        )

        return isDifferentCompetition && isPublished && hasOverlappingPaths
      })

      const newReservedDates = filteredCompetition?.map((competition) => {
        const startDate = new Date(competition.startDate)
        const endDate = new Date(competition.endDate)

        // Set start date to midnight (00:00)
        startDate.setHours(0, 0, 0, 0)
        // Set end date to the end of the day (23:59:59:999)
        endDate.setHours(23, 59, 59, 999)
        return {
          startDate,
          endDate,
        }
      })

      return newReservedDates
    }

    setReservedDates(calculateReservedDates())
  }, [competition?.selectedPaths, allCompetitions, competition?._id])

  const saveCompetition = () => {
    try {
      const isCompetitionValidated = basicValidateCompetition(
        competition,
        enqueueSnackbar,
      )

      if (!isCompetitionValidated) return

      // check if it is a valid date & if overlapped
      if (competition?.startDate && competition?.endDate) {
        const validDate = Helper.validateDateRange(
          competition.startDate,
          competition.endDate,
          enqueueSnackbar,
        )

        let dateOverlapped = false

        const startDate = new Date(competition.startDate)
        const endDate = new Date(competition.endDate)

        // Filter the current competition out from the array
        const allOtherCompetitions = allCompetitions.filter(
          (existingComp) => existingComp._id !== competition._id,
        )

        // Filter competitions that do share any selectedPath with the new competition
        const allActiveOverlappedPathsCompetition = allOtherCompetitions.filter(
          (existingCompetition) => {
            // Check if none of the selected paths of existingCompetition match those of new competition
            return existingCompetition.selectedPaths.some(
              (existingPath) =>
                competition.selectedPaths.some(
                  (newPath) => newPath._id === existingPath._id,
                ) && existingCompetition.state === 'published',
            )
          },
        )

        for (let i = 0; i < allActiveOverlappedPathsCompetition?.length; i++) {
          const oneCompetition = allActiveOverlappedPathsCompetition?.[i]

          const existingStartDate = new Date(oneCompetition.startDate)
          const existingEndDate = new Date(oneCompetition.endDate)

          if (startDate <= existingEndDate && existingStartDate <= endDate) {
            dateOverlapped = true
            snackProgressBar(
              enqueueSnackbar,
              {
                success: '',
                fail: OVERLAP_DATE_MESSAGE,
              },
              false,
            )
            break
          }
        }

        if (!validDate || dateOverlapped) return
      }

      const compEndDate = new Date(competition.endDate)
      compEndDate.setUTCHours(23, 59, 59, 999)
      competition.endDate = compEndDate.toISOString()

      const promise = Helper.ApiRequest(`/competitionsNew/${competition._id}`, {
        method: 'PUT',
        body: JSON.stringify(competition),
      })

      const onSuccess = () => {
        history.push('/competitions')
      }

      doAndSnack(promise, enqueueSnackbar, {
        success: 'Competition succesfully saved',
        fail: 'Failed to save competition',
        onSuccess,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        {title}
      </Typography>
      {!isLoading ? (
        <Paper className={classes.paper}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            //   action={this.getAction}
          >
            <Tab label="Details" />
            <Tab label="Challenges" />
          </Tabs>

          {tabValue === 0 && !isLoading && (
            <TabContainer>
              <CompetitionForm
                // @ts-expect-error legacy
                competition={competition}
                onChange={handleChange}
                onSave={saveCompetition}
                reservedDates={reservedDates}
              />
            </TabContainer>
          )}
          {tabValue === 1 && (
            <ChallengeTab
              challenges={challenges}
              onAdd={() => addChallenge(enqueueSnackbar)}
            />
          )}
        </Paper>
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </>
  )
}

export default withSnackbar(
  // @ts-expect-error legacy
  withDrawerContext(
    withRouter(withTheme()(withStyles(styles)(CompetitionDetails))),
  ),
)

function TabContainer({
  children,
}: {
  children: ReactElement | ReactElement[]
}) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  )
}

const ChallengeTab = withStyles(styles)(
  ({
    challenges,
    classes,
    e,
    onAdd,
  }: {
    challenges: IChallenge[]
    classes?: any
    e?: any
    onAdd: () => void
  }) => {
    return (
      <TabContainer>
        <ChallengeTable data={challenges} />
        <Fab
          color="secondary"
          aria-label="Add"
          className={classes.fab}
          onClick={onAdd}
        >
          <AddIcon />
        </Fab>
      </TabContainer>
    )
  },
)
