import { withPaths } from '../contexts/data-context'
import {
  Grid,
  withStyles,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import { IPath } from '../pages/competitions/type'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import React, { useState } from 'react'
import { CancelButton, DeleteButton, OutlinedField, SaveButton } from './Forms'
import CardControl from './ImageControl'
import { DeleteCompetition } from './Delete'
import { DangerZone } from './Grids'
import Calendar from '@demark-pro/react-booking-calendar'
import { OVERLAP_DATE_MESSAGE } from '../constants/ErrorMessages'
import { snackProgressBar } from '../utils/snackbar'
import TitleField from './field/TitleField'
import StateField from './field/StateField'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // textAlign: 'center',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  listTextField: {
    width: 'calc(100% - 50px)', // We give place to the icon
    marginTop: '0',
    marginBottom: '0',
    paddingTop: '0',
  },
})

const CompetitionForm = (props) => {
  const {
    history,
    classes,
    competition,
    paths,
    onSave,
    onChange,
    enqueueSnackbar,
    reservedDates,
  } = props

  const {
    title,
    state,
    selectedPaths,
    startDate,
    endDate,
    _id,
    thumbnail,
    description,
  } = props.competition

  const handleChange = (field) => (event) => {
    competition[field] = event.target.value

    if (field === 'title' && event.target.value.length > 100) return

    onChange(competition)
  }

  const handleChangeUrl = (field) => (value) => {
    competition[field] = value
    onChange(competition)
  }

  const handleChangeLevelChecbox = (event) => {
    const pathsId = event.target.value

    // Find the path object by its _id
    const pathData = paths.find((path) => path._id === pathsId)

    if (
      competition.selectedPaths.some(
        (oneSelectedPath) => oneSelectedPath._id === pathsId,
      )
    ) {
      // Remove the path object from the array
      competition.selectedPaths = competition.selectedPaths.filter(
        (onePath) => onePath._id !== pathsId,
      )
    } else {
      // Add the path object to the array
      if (pathData) {
        competition.selectedPaths = [...competition.selectedPaths, pathData]
      }
    }

    onChange(competition)
  }

  const [selectedDates, setSelectedDates] = useState([startDate, endDate])

  const handleChangeDate = (e) => {
    setSelectedDates(e)

    if (!Array.isArray(e) || e?.length === 0) return

    let startDate = ''
    let endDate = ''

    if (e.length === 1) {
      // If only one date is selected, assign it to both startDate and endDate
      startDate = endDate = formatToDate(e[0])
    } else if (e.length > 1) {
      // If two dates are selected, assign them to startDate and endDate respectively
      startDate = formatToDate(e[0])
      endDate = formatToDate(e[1])
    }

    competition.startDate = startDate
    competition.endDate = endDate

    onChange(competition)
  }

  const formatToDate = (date) => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // getMonth() is zero-based
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const handleUploadThumbnail = () => {
    onSave()
  }

  const handleSave = async (e) => {
    e.preventDefault()
    return onSave()
  }

  const handleCancel = () => {
    history.push('/competitions')
  }

  const textProps = { classes, handleChange }
  // const selectedPathsProps = {
  //   classes,
  //   onChange: handleChangeLevel,
  //   paths,
  //   id: path && path._id ? path._id : '',
  // }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={24} alignItems="flex-start">
        <Grid item xs={12} sm={6} md={12}>
          <TitleField {...textProps} value={title} />
        </Grid>

        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
          spacing={24}
        >
          <Grid item xs={12} sm={12} md={12}>
            {paths?.map((path: IPath) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeLevelChecbox}
                      value={path?._id}
                      checked={selectedPaths.some(
                        (oneSelectedPath) => oneSelectedPath._id === path?._id,
                      )}
                    />
                  }
                  label={`Level: ${path?.level}`}
                />
              )
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <StateField {...textProps} value={state} />
        </Grid>

        <Grid
          container
          item
          xs={12}
          sm={6}
          md={4}
          direction="column"
          alignContent="center"
          justify="center"
          style={{ textAlign: 'center' }}
        >
          <Grid item xs={12}>
            {competition?.isParticipated && (
              <b>Competition has been participated</b>
            )}
          </Grid>
          <Grid item xs={12}>
            <Calendar
              style={{ direction: 'ltr' }}
              selected={selectedDates}
              onChange={handleChangeDate}
              range={true}
              disabled={competition?.isParticipated}
              reserved={reservedDates}
              onOverbook={(e, err) =>
                snackProgressBar(
                  enqueueSnackbar,
                  { success: '', fail: OVERLAP_DATE_MESSAGE },
                  false,
                )
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <DescriptionField {...textProps} value={description} />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <CardControl
            /* @ts-expect-error legacy */
            url={thumbnail ?? ''}
            title="Thumbnail"
            onUpload={handleUploadThumbnail}
            onChangeUrl={handleChangeUrl('thumbnail')}
            uploadDefaultPrefix="competition"
            uploadDefaultName={`th-competition-${title}`}
            maxFileSize={5 * 1024 * 1024} //  5Mb
          />
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={12} sm={3} md={2}>
            <SaveButton onClick={handleSave} />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <CancelButton onClick={handleCancel} />
          </Grid>
        </Grid>

        {competition?._id && (
          <DangerZone>
            {competition?.isParticipated ? (
              <Typography variant="h5">
                لايمكن حذف المساق طالما تسابق على سباقاته أطفال
              </Typography>
            ) : (
              <DeleteCompetition
                competition={competition}
                onAfterDelete={() => history.replace('/competitions')}
                component={DeleteButton}
              />
            )}
          </DangerZone>
        )}
      </Grid>
    </form>
  )
}

export default withPaths(
  withSnackbar(withRouter(withStyles(styles)(CompetitionForm))),
)

const DescriptionField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Description"
      className={classes.textField}
      value={value}
      onChange={handleChange('description')}
      multiline
      // rowsMax="4"
      rows="4"
    />
  )
}
