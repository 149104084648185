import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import MaterialTable from 'material-table'
import Checkbox from '@material-ui/core/Checkbox'
import TablePagination from '@material-ui/core/TablePagination'
import Helper from '../../utils/Helper'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

// The pagination component is not right with rtl
const components = {
  Pagination: (props: any) => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

const Episodes = () => {
  const [episodes, setEpisodes]: any = useState([])
  const history = useHistory()

  useEffect(() => {
    fetchEpisodes()
  }, [])

  const fetchEpisodes = async () => {
    const data = await Helper.ApiRequest('/episodes')
    setEpisodes(data)
  }

  const pageTitle = 'Episodes'

  const columns = [
    { title: 'Level', field: 'level' },
    { title: 'Series', field: 'seriesName' },
    { title: 'Title', field: 'title' },
    { title: 'Order', field: 'order' },
    { title: 'Chapters', field: 'nbChapters' },
    { title: 'Games', field: 'nbActivities' },
    {
      title: 'Published',
      field: 'state',
      type: 'boolean',
      render: (rowData) => (
        <Checkbox checked={rowData.state === 'published'} disabled />
      ),
      customFilterAndSearch: (term, rowData) => {
        if (term === 'checked') {
          return rowData.state === 'published'
        } else if (term === 'unchecked') {
          return rowData.state !== 'published'
        }
      },
    },
  ]

  const options = {
    columnsButton: true,
    exportButton: true,
    filtering: true,
    emptyRowsWhenPaging: false,
    pageSize: 10,
    actionsColumnIndex: -1,
  }

  const handleEdit = (e, rowData) => {
    history.push(`/episodes/${rowData.slug}`)
  }

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Edit episode',
      onClick: (e, row) => {
        handleEdit(e, row)
      },
    },
  ]

  return (
    <MaterialTable
      // @ts-expect-error legacy
      columns={columns}
      data={episodes}
      title={pageTitle}
      options={options}
      components={components}
      actions={actions}
    />
  )
}

export default withRouter(Episodes)
