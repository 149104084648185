import { IChallenge } from '@pages/competitions/type'
import Checkbox from '@material-ui/core/Checkbox'
import TablePagination from '@material-ui/core/TablePagination'
import MaterialTable from 'material-table'
import React from 'react'
import { withRouter } from 'react-router-dom'

const pageTitle = 'Challenges'
const columns = [
  { title: 'Title', field: 'title' },
  { title: 'Start Date', field: 'startDate' },
  { title: 'End Date', field: 'endDate' },
  {
    title: 'Published',
    field: 'state',
    type: 'boolean',
    render: (rowData) => (
      <Checkbox checked={rowData.state === 'published'} disabled />
    ),
  },
]
const options = {
  sorting: true,
  columnsButton: true,
  exportButton: true,
  emptyRowsWhenPaging: false,
  pageSize: 10,
  actionsColumnIndex: -1,
}

// The pagination component is not right with rtl
const components = {
  Pagination: (props) => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

const ChallengeTable = (props) => {
  const { history, data } = props

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Edit challenge',
      onClick: (e, row) => {
        handleEdit(e, row)
      },
    },
  ]

  const handleEdit = (e, rowData: IChallenge) => {
    history.push({
      pathname: `/challenges/${rowData.competition._id}`,
      state: rowData,
    })
  }

  return (
    <React.Fragment>
      <MaterialTable
        // @ts-expect-error legacy
        columns={columns}
        data={data}
        title={pageTitle}
        options={options}
        components={components}
        actions={actions}
      />
    </React.Fragment>
  )
}

export default withRouter(ChallengeTable)
