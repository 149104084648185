import Config from '../config'
import AES from 'crypto-js/aes'
import encUtf8 from 'crypto-js/enc-utf8'
import { snackProgressBar } from './snackbar'

// @TODO: filter what we need in this file from what we don't
const Helper = {
  ApiRequest: async function (
    url,
    options: any = {},
    auth = true,
    overideOptions = false,
    token = false,
  ): Promise<any> {
    // TODO test if real url
    url = Config.apiURL + url

    if (!options) options = {}
    if (!options.headers) options.headers = {}

    options.headers['Screen-Resolution'] =
      window.screen.width * window.devicePixelRatio +
      ' X ' +
      window.screen.height * window.devicePixelRatio
    options.headers['Application-Id'] = 'dashboard'

    if (!overideOptions) {
      if (!options.headers) options.headers = {}

      if (!options.headers.Accept) {
        options.headers.Accept = 'application/json'
      }

      if (!options.headers['Content-Type']) {
        options.headers['Content-Type'] = 'application/json'
      }
    }

    if (auth) {
      if (!options.headers) options.headers = {}
      options.headers.Authorization = !token
        ? Helper.token()
        : 'Bearer ' + token
    }

    const promise = new Promise(function (resolve, reject) {
      fetch(url, options)
        .then(async function (response) {
          if (response.status === 401) {
            // 401 Unauthorized access
            Helper.logOut()
            location.replace('/login')
            return
          }
          if (response.status === 204) {
            resolve(true)
            return
          }
          if (response.status === 404) {
            reject(new Error('المحتوى المطلوب غير متوفر'))
            return
          }
          if (response.status === 500) {
            reject(new Error('المعذرة. لقد حدث عطل بالخادم'))
            return
          }
          await response.json().then(function (data) {
            if (response.status === 400) {
              resolve({ error: true, message: data })
            }
            resolve(data)
          })
        })
        .catch(function (err) {
          reject(err)
        })
    })
    return await promise
  },
  isAuthenticated: function () {
    return Helper.getLocalStorageItem(Config.localStorage.tokenIndex) !== null
  },
  token: function () {
    return (
      'Bearer ' + Helper.getLocalStorageItem(Config.localStorage.tokenIndex)
    )
  },
  logOut: function () {
    localStorage.removeItem(Config.localStorage.keyName)
  },
  log: function () {
    if (Config.enableLog)
      // eslint-disable-next-line prefer-rest-params
      console.log.apply(this, arguments)
  },
  whoami: async function (forcenew = false) {
    const promise = new Promise((resolve, reject) => {
      const me = Helper.getLocalStorageItem(Config.localStorage.whoamiIndex)
      const exists = me != null

      if (!forcenew && exists) {
        resolve(JSON.parse(me))
        return
      }

      const url = '/me'
      Helper.ApiRequest(url)
        .then((profile) => {
          Helper.setLocalStorageItem(
            Config.localStorage.whoamiIndex,
            JSON.stringify(profile),
          )
          resolve(profile)
        })
        .catch((err) => {
          reject(err)
        })
    })
    return await promise
  },
  date: function (mongooseDate) {
    const date = new Date(mongooseDate)
    return date.toLocaleString()
  },
  setEncryptionKey: async function (token) {
    const promise = new Promise((resolve, reject) => {
      const key = Helper.getLocalStorageItem(Config.localStorage.keyName)
      if (key) {
        resolve(key)
      } else {
        const url = '/key'
        Helper.ApiRequest(url, null, true, false, token)
          .then((key) => {
            Helper.setLocalStorageItem(Config.localStorage.keyName, key)
            resolve(key)
          })
          .catch((err) => {
            reject(err)
          })
      }
    })
    return await promise
  },
  getLocalStorageItem: function (key = null) {
    let storage = localStorage.getItem(Config.localStorage.keyName)
    if (storage == null) return null

    const encryptionKey = storage.substr(
      storage.length - Config.localStorage.keyLength,
    )

    if (key === Config.localStorage.keyName) {
      return encryptionKey
    }

    storage = storage.slice(0, -Config.localStorage.keyLength)

    // if after slicing, the storage is empty
    if (storage === '') {
      return null
    } else {
      const bytes = AES.decrypt(storage, encryptionKey)
      const obj = JSON.parse(bytes.toString(encUtf8))
      // if key is not specified, return the whole storage as object
      if (!key) {
        return obj
      } else {
        return obj[key]
      }
    }
  },
  setLocalStorageItem: function (key, value) {
    let storage = localStorage.getItem(Config.localStorage.keyName)

    // if the key to be added is the encryption key appending the encryption key to localstorage without encrypting it
    if (key === Config.localStorage.keyName && storage == null) {
      localStorage.setItem(key, value)
      return value
    } else {
      // if a different key is to be set || the storage is containing something else

      // if there is already stored data, it means the key is in the end of the string
      const encryptionKey = storage.substr(
        storage.length - Config.localStorage.keyLength,
      )
      let encryptedValue, obj

      storage = storage.slice(0, -Config.localStorage.keyLength)

      if (storage === '') {
        // at this point, we leant the encryption key is in the localstorage (localstorage containing encryption key only), we set the new value
        if (key === Config.localStorage.keyName) {
          localStorage.setItem(key, value)
          return
        }

        obj = {}
        obj[key] = value
        encryptedValue = AES.encrypt(JSON.stringify(obj), encryptionKey)
        // we append the encryption key
        encryptedValue += encryptionKey
        localStorage.setItem(Config.localStorage.keyName, encryptedValue)
        return true
      } else {
        obj = Helper.getLocalStorageItem()
        delete obj[key]
        obj[key] = value
        // var obj = {key:value};
        encryptedValue = AES.encrypt(JSON.stringify(obj), encryptionKey)
        // we append the encryption key again
        encryptedValue += encryptionKey
        localStorage.setItem(Config.localStorage.keyName, encryptedValue)
        return true
      }
    }
  },
  MediaURL: function (resource, medium = 'file') {
    const isDbIdRegex = new RegExp('^[0-9a-fA-F]{24}$')
    if (!isDbIdRegex.test(resource)) return resource // not a database ID

    let accessToken = Helper.getLocalStorageItem(Config.localStorage.tokenIndex)
    if (accessToken) accessToken = '?access_token=' + accessToken

    const url =
      Config.apiURL + '/media/' + medium + '/' + resource + accessToken
    console.log('MediaURL Generated\n', url)
    return url
  },
  uploadAsset: async (path, formData) => {
    return await Helper.ApiRequest(
      `/assets/upload?filename=${path}`,
      {
        method: 'POST',
        body: formData,
      },
      true,
      true, // don't override content-type for multipart data
    )
  },
  uploadVideoToHLS: async (formData) => {
    return await Helper.ApiRequest(
      '/assets/video/upload/mp4',
      {
        method: 'POST',
        body: formData,
      },
      true,
      true, // don't override content-type for multipart data
    )
  },
  validateURL: (url) => {
    try {
      // eslint-disable-next-line no-new
      new URL(url)
      return true
    } catch (error) {
      return false
    }
  },
  isJsonString: (data) => {
    try {
      if (typeof data !== 'object') return false

      JSON.parse(JSON.stringify(data))
    } catch (e) {
      console.log(e)
      return false
    }
    return true
  },
  validateDateRange: (startDate, endDate, enqueueSnackbar) => {
    try {
      const start = new Date(startDate)
      const end = new Date(endDate)

      // Check if both dates are valid
      if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        throw new Error('Invalid date format')
      }

      // Check if the start date is before or the same as the end date
      if (start > end) {
        throw new Error('Start date cannot be after end date')
      }

      // Dates are valid and the range is correct
      return true
    } catch (error) {
      snackProgressBar(
        enqueueSnackbar,
        {
          success: '',
          fail: error?.message ?? 'ERROR FOR DATEPICKER',
        },
        false,
      )

      return false
    }
  },
}

export default Helper
