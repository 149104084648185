import React from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

class ActionMenu extends React.Component {
  props: any
  states: any
  handleAddSeries = () => {
    this.props.history.push('/series/add')
  }

  handleAddEpisode = () => {
    this.props.history.push('/episodes/add')
  }

  handleAddChapter = () => {
    this.props.history.push('/chapters/add')
  }

  handleAddCompetitions = () => {
    this.props.history.push('/competitions/add')
  }

  render() {
    const { open, anchorEl } = this.props

    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        // @ts-expect-error legacy
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleAddSeries}>Add series</MenuItem>
        <MenuItem onClick={this.handleAddEpisode}>Add episode</MenuItem>
        <MenuItem onClick={this.handleAddChapter}>Add chapter</MenuItem>
        <MenuItem onClick={this.handleAddCompetitions}>
          Add competition
        </MenuItem>
      </Menu>
    )
  }
}
// @ts-expect-error legacy
ActionMenu.defaultProps = {
  open: false,
}

export default withRouter(ActionMenu)
